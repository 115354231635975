(function ($) {
	
	var Grafikfabriken = function(){
		
		var self = this;

		var s = {
			checkboxes : $('input[type="checkbox"]'),
			quantityBox : $('.tickets .quantity')
		};


		self.init = function(){
			self.prettyCheckboxes();

			s.quantityBox.on('click', '.plus', self.addToQty);
			s.quantityBox.on('click', '.minus', self.removeFromQty);
		};

		self.addToQty = function(){
			var $this = $(this);
			var $input = $this.closest( '.quantity' ).find('input');
			var val = parseInt( $input.val() );
			if (
				typeof $input.attr('max') !== 'undefined' &&
				(val+1) <= parseInt($input.attr('max'))
			){
				val++;
			}

			$input.val( val );
		};

		self.removeFromQty = function(){
			var $this = $(this);
			var $input = $this.closest( '.quantity' ).find('input');
			var val = parseInt( $input.val() );
			val--;
			val = (val > 0) ? val : 1;

			$input.val( val );
		};



		self.prettyCheckboxes = function() {
			$.each(s.checkboxes, function() {
				$(this).parent().wrapInner('<div class="pretty primary"></div>');
				$(this).siblings('span, label.checkbox').wrap('<label></label>').prepend('<i class="fa fa-check"></i> ');
			});
		};


		self.init();

	};


	$( document ).ready( function(){
		new Grafikfabriken();
	});
})(jQuery);
