(function($) {
    'use_strict';

    var stretch = function() {

        if ($("body").find(".stretch").length === 0)
            return false;

        var self = this;
        self.class = ".stretch";
        //$(window) = $(window);

        self.init = function() {
            $(window).bind("resize load stretch", self.do_stretch);
        };

        self.do_stretch = function(evt) {

            if(evt.type !== 'stretch'){
            if (!self.do_scroll($(window))){
                return false;
                }
            }

            var ww = $(window).outerWidth();
            var wh = $(window).outerHeight();
            $("body").find(self.class).each(function() {
                var element = $(this);


                if (element.hasClass("fullheight")) {
                    element.css({ "width": ww + 5 + "px", "height": wh, "margin-left": "-" + (ww / 2) + "px" });
                    if (element.hasClass("parallax")) {
                        element.find("div.row").each(function() {
                            var elm = $(this);
                            elm.css({
                                "margin-top": (wh / 2) - (elm.outerHeight() / 2)
                            });
                        });
                    }
                } else {
                    element.css({ "width": ww + "px", "margin-left": "-" + ((ww) / 2) + "px" });
                }

            });
        };


        self.width = 0;
        self.height = 0;
        self.do_scroll = function(window) {

            if (window.outerWidth() !== self.width) {
                self.width = window.outerWidth();
                return true;
            }

            return false;
        };


        self.init();
    };

    //Init stretch
    $(document).ready(function() {
        new stretch();
    });

})(jQuery, document, window);