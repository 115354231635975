(function($) {
	var grid = function() {

		var s = this;

		s.settings = {
			grid : $('.gf-block-section')
		}

		s.init = function() {
			s.createGrid();
		}

		s.createGrid = function() {
			s.wrapItems();
		}

		s.wrapItems = function() {
			s.settings.grid.each(function() {
				$(this).find('.gf-block').each(function() {
					$(this).wrapInner('<div class="gf-block-container"></div>');
				});
			});
		}


		s.init();


	}
	$(document).ready(function() {
		new grid();
	})
})(jQuery);