(function($) {
    
        var mobile_menu = function() {
            
            var s = this;
            s.breakPoint = 768;
            s.windowWidth = 0;
            s.windowHeight = 0;
            s.element = $("#expand-main-nav");
            s.button = $("button.navbar-toggle");
            s.items = s.element.find('li > a');
            s.visible = false;
    
            s.init = function() {
                $(window).on("resize load scroll touchmove", s.resize);
                s.button.on('click touchstart', s.showMenu);    
                s.addCloseBtn();
            };
    
            s.ulClick = function(event) {
                event.stopPropagation();
                event.preventDefault();
            };
    
            s.aClick = function(event) {
                return true;
            };
    
            s.showMenu = function(e) {

                e.stopPropagation();
                e.preventDefault();

                if(s.visible === false){
                    s.visible = true;
                    s.button.stop().removeClass("collapsed out");
                    s.element.stop().addClass("in").show();
                }else{
                    s.hideMenu(e);
                }

                s.resize(e);
                
            };
    
            s.hideMenu = function(e) {
                s.element.stop().removeClass("in").addClass("out");
                s.button.stop().addClass("collapsed");
                s.visible = false;

                setTimeout(function() {
                    if(s.visible == false){
                        s.element.removeClass("out");
                        s.element.hide();
                    }
                }, 480);
            };
    
            s.resize = function(event) {
                s.windowWidth = $(window).outerWidth();
                s.windowHeight = $(window).outerHeight();
    
                if (s.windowWidth <= s.breakPoint) {
                    if (s.visible === true) {
                        height = (typeof window.outerHeight != 'undefined') ? Math.max(window.outerHeight, $(window).height()) : $(window).height();
                        s.element.css({ height: height - $("#main-nav").outerHeight() });
                    }
    
                } else if (event.type !== "scroll") {
                    s.element.css({ height: 'auto' });
                }
            };

    
            /**
             * Adds the close button on the open mobile menu
             */
            s.addCloseBtn = function() {
                var closeIcon = '<div class="mobile-close"><span></span><span></span></div>';
                var closeBtn = '<li class="pre-menu visible-xs"> <a href="#">' + closeIcon + 'Stäng </a></li>';
    
                s.element.find('ul').prepend(closeBtn);
            };
    
    
            s.init();
            return s;
        };
    
        new mobile_menu();
    
    })(jQuery);