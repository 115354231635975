(function($){
    $(window).load(function(){
        $('.grid').masonry({
            // set itemSelector so .grid-sizer is not used in layout
            itemSelector: '.trend',
            // use element for option
            columnWidth: '.grid-sizer',
            percentPosition: true
          });
    });
    
})(jQuery);